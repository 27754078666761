const state = {
  liscenceLog: false,
  liscence: 'Media Voices For Children Streaming License',
};

const getters = {
  isLiscenceLogOpen: state => state.liscenceLog,
  liscence: state => state.liscence,
};

const actions = {
  TOGGLE_LISCENCE_LOG({ commit }) {
    commit('toggleLiscenceLog');
  },
};

const mutations = {
  toggleLiscenceLog: state => (state.liscenceLog = !state.liscenceLog),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
