import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { projectAuth } from '@/firebase/config';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Signup.vue'),
  },
  {
    path: '/reset-password',
    name: 'Reset',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Reset.vue'),
  },
  {
    path: '/setup',
    name: 'Setup',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ProfileSetup.vue'),
  },
  {
    path: '/manage-products',
    name: 'ManageProducts',
    component: () => import('../views/ManageProducts.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/manage-users',
    name: 'ManageUsers',
    component: () => import('../views/ManageUsers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Check to see if route requires auth
  if (to.matched.some(rec => rec.meta.requiresAuth)) {
    // Check auth state of user
    let user = projectAuth.currentUser;
    if (user) {
      // User signed in, proceed to route
      next();
    } else {
      // No user signed in, redirect to login
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

export default router;
