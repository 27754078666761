<template>
  <v-dialog
    v-model="isCheckoutOpen"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card v-if="checkoutProduct.data">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="TOGGLE_CHECKOUT"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-toolbar-title
          >Checkout for: {{ checkoutProduct.data.name }}</v-toolbar-title
        >
      </v-toolbar>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="8">
            <v-stepper v-model="step">
              <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1"
                  >Streaming Liscence Agreement</v-stepper-step
                >
                <v-stepper-step :complete="step > 2" step="2"
                  >Checkout</v-stepper-step
                >
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <a href="/MVC Streaming License.pdf" target="_blank">{{
                    liscence
                  }}</a>
                  <v-checkbox
                    v-model="checkbox"
                    label="By checking this box you agree to adhere to the above terms"
                    class="pl-3 mb-6"
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    :disabled="!checkbox"
                    @click="triggerCheckout"
                    >Next</v-btn
                  >
                </v-stepper-content>

                <v-stepper-content step="2">
                  <h2 class="mb-4">
                    You are subscribing to {{ checkoutProduct.data.name }} for
                    ${{ checkoutProduct.data.price }}/yr
                  </h2>
                  <v-img
                    class="mb-4"
                    max-height="400"
                    contain
                    :aspect-ratio="2 / 3"
                    :src="checkoutProduct.data.image"
                  ></v-img>

                  <div id="paypal-button"></div>

                  <!-- <v-btn color="primary" @click="CHECKOUT">Checkout</v-btn> -->
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Checkout',
  computed: mapGetters(['isCheckoutOpen', 'checkoutProduct', 'liscence']),
  methods: {
    ...mapActions(['TOGGLE_CHECKOUT', 'ADD_PRODUCT_TO_USER', 'GET_USER']),
    triggerCheckout() {
      this.step = 2;
      const script = document.createElement('script');
      script.src =
        'https://www.paypal.com/sdk/js?client-id=AQ3kQQaboOWdISWDrZmkWzfb_wbmzPORudk3d_IXBEYJJSxeEJR1yi--oXSAV3mnh5YePIMktdk26akC&vault=true&intent=subscription';
      script.addEventListener('load', this.setLoaded);
      document.body.appendChild(script);
    },
    setLoaded() {
      window.paypal
        .Buttons({
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: this.checkoutProduct.data.planId,
            });
          },
          onApprove: (data, actions) => {
            // Trigger action to add subscription and product to user
            const subscriptionId = data.subscriptionID;
            this.ADD_PRODUCT_TO_USER(subscriptionId);
            this.TOGGLE_CHECKOUT();
            this.GET_USER();
          },
        })
        .render('#paypal-button');
    },
  },
  data() {
    return {
      step: 1,
      checkbox: false,
    };
  },
};
</script>
