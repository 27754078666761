import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyDlIfBAN_yc2Z7cK86zdJMc1oJ7kMzYXFw',
  authDomain: 'mvc-prod.firebaseapp.com',
  projectId: 'mvc-prod',
  storageBucket: 'mvc-prod.appspot.com',
  messagingSenderId: '532345936283',
  appId: '1:532345936283:web:b02f216c0f70f321a2e93e',
  measurementId: 'G-EL90C3X7D4',
};

// Init firebase
const app = firebase.initializeApp(firebaseConfig);

// Init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();
const analytics = firebase.analytics();

// firestore helper functions
const addToArray = data => firebase.firestore.FieldValue.arrayUnion(data);
const removeFromArray = data => firebase.firestore.FieldValue.arrayRemove(data);

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {
  projectFirestore,
  projectAuth,
  projectStorage,
  timestamp,
  addToArray,
  removeFromArray,
};
